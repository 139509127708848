import styled from 'styled-components'

const FooterStyle = styled.footer`
	--footerPadding: var(--sp13x) 0 var(--sp5x) 0;
	--imageProportion: var(--sp12x);
	--imageWidth: var(--sp29x);
	--addressBlockMarginTop: var(--sp6x);
	--phoneBlockMarginTop: var(--sp2x);
	--socialsWrapMarginTop: var(--sp6x);
	--socialIconMarginRight: var(--sp4x);
	--euBankWidth: var(--sp28x);
	--euUnionWidth: var(--sp13x);
	--euLogosPaddingTop: var(--sp12x);
	--euLogosWrapMarginTop: var(--sp6x);
	--menuListMarginTop: var(--sp3x);
	--menuLinkMarginTop: var(--sp2x);
	--miniTitleMarginTop: var(--sp4x);
	--firstLinkMarginTop: var(--sp3x);
	--publicationsMarginTop: var(--sp8x);
	--mediaCenterMarginTop: var(--sp8x);
	--euBankMarginRight: var(--sp4x);

	--copyRightMarginTop: var(--sp10x);
	--supportTextMarginTop: var(--sp8x);

	padding: var(--footerPadding);

	.infos-cont {
		.support-text {
			margin-top: var(--supportTextMarginTop);
		}
	}

	.enterprise-armenia-logo {
		--proportion: var(--imageProportion);
		width: var(--imageWidth);

		img {
			object-fit: contain;
		}
	}

	.address-block {
		margin-top: var(--addressBlockMarginTop);
	}

	.phone-block {
		margin-top: var(--phoneBlockMarginTop);
	}

	.socials-wrap {
		display: flex;
		align-items: center;
		margin-top: var(--socialsWrapMarginTop);

		.social-link {
			margin-right: var(--socialIconMarginRight);
			transition: var(--trTime);
			transform: scale(1);

			@media (hover: hover) {
				&:hover {
					transform: scale(1.2);
				}
			}

			.social-icon {
				font-size: var(--h5);
			}

			&:last-child {
				margin-right: unset;
			}
		}
	}

	.eu-logos-wrap {
		display: flex;
		margin-top: var(--euLogosWrapMarginTop);

		.eu-union {
			width: var(--euUnionWidth);
			padding-top: var(--euLogosPaddingTop);

			img {
				object-fit: contain;
			}
		}

		.eu-bank {
			width: var(--euBankWidth);
			padding-top: var(--euLogosPaddingTop);
			margin-right: var(--euBankMarginRight);

			img {
				object-fit: contain;
			}
		}
	}

	.menu-list {
		display: flex;
		flex-direction: column;
		margin-top: var(--menuListMarginTop);

		.menu-link {
			position: relative;
			width: fit-content;
			margin-bottom: var(--menuLinkMarginTop);

			&::before {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				width: 0;
				height: 2px;
				background-color: var(--white);
				transition: var(--trTime);
			}

			@media (hover: hover) {
				&:hover {
					&::before {
						width: 100%;
					}
				}
			}

			&:last-child {
				margin-bottom: unset;
			}
		}
	}

	.mini-title {
		margin-top: var(--miniTitleMarginTop);
	}

	.first-link {
		position: relative;
		display: block;
		width: fit-content;
		margin-top: var(--firstLinkMarginTop);

		&::before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 0;
			height: 2px;
			background-color: var(--white);
			transition: var(--trTime);
		}

		@media (hover: hover) {
			&:hover {
				&::before {
					width: 100%;
				}
			}
		}
	}

	.publications {
		position: relative;
		display: block;
		width: fit-content;
		margin-top: var(--publicationsMarginTop);

		&::before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 0;
			height: 2px;
			background-color: var(--white);
			transition: var(--trTime);
		}

		@media (hover: hover) {
			&:hover {
				&::before {
					width: 100%;
				}
			}
		}
	}

	.media-centre-title {
		margin-top: var(--mediaCenterMarginTop);
	}

	.copyright-block {
		display: flex;
		justify-content: space-between;
		margin-top: var(--copyRightMarginTop);
		opacity: 50%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--footerPadding: var(--sp10x) 0 var(--sp4x) 0;
		--imageProportion: var(--sp10x);
		--imageWidth: var(--sp24x);
		--addressBlockMarginTop: var(--sp4x);
		--phoneBlockMarginTop: var(--sp2x);
		--socialsWrapMarginTop: var(--sp4x);
		--socialIconMarginRight: var(--sp4x);
		--euBankWidth: var(--sp21x);
		--euUnionWidth: var(--sp10x);
		--euLogosPaddingTop: var(--sp9x);
		--euLogosWrapMarginTop: var(--sp5x);

		--menuListMarginTop: var(--sp3x);
		--menuLinkMarginTop: var(--sp2x);
		--miniTitleMarginTop: var(--sp3x);
		--firstLinkMarginTop: var(--sp3x);
		--publicationsMarginTop: var(--sp5x);
		--mediaCenterMarginTop: var(--sp6x);
		--euBankMarginRight: var(--sp3x);
		--copyRightMarginTop: var(--sp8x);
		--supportTextMarginTop: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--footerPadding: var(--sp8x) 0 var(--sp4x) 0;
		--imageProportion: var(--sp9x);
		--imageWidth: var(--sp21x);
		--addressBlockMarginTop: var(--sp3x);
		--phoneBlockMarginTop: var(--sp2x);
		--socialsWrapMarginTop: var(--sp3x);
		--socialIconMarginRight: var(--sp3x);
		--euBankWidth: var(--sp16x);
		--euUnionWidth: var(--sp8x);
		--euLogosPaddingTop: var(--sp7x);
		--euLogosWrapMarginTop: var(--sp5x);

		--menuListMarginTop: var(--sp3x);
		--menuLinkMarginTop: var(--sp2x);
		--miniTitleMarginTop: var(--sp3x);
		--firstLinkMarginTop: var(--sp3x);
		--publicationsMarginTop: var(--sp5x);
		--mediaCenterMarginTop: var(--sp5x);
		--euBankMarginRight: var(--sp2x);

		--copyRightMarginTop: var(--sp5x);
		--supportTextMarginTop: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--footerPadding: var(--sp8x) 0 var(--sp4x) 0;
		--imageProportion: var(--sp8x);
		--imageWidth: var(--sp19x);
		--addressBlockMarginTop: var(--sp3x);
		--phoneBlockMarginTop: var(--sp1-5x);
		--socialsWrapMarginTop: var(--sp3x);
		--socialIconMarginRight: var(--sp3x);
		--euBankWidth: var(--sp15x);
		--euUnionWidth: var(--sp8x);
		--euLogosPaddingTop: var(--sp7x);
		--euLogosWrapMarginTop: var(--sp5x);

		--menuListMarginTop: var(--sp3x);
		--menuLinkMarginTop: var(--sp1-5x);
		--miniTitleMarginTop: var(--sp3x);
		--firstLinkMarginTop: var(--sp3x);
		--publicationsMarginTop: var(--sp5x);
		--mediaCenterMarginTop: var(--sp5x);
		--euBankMarginRight: var(--sp2x);

		--copyRightMarginTop: var(--sp5x);
		--supportTextMarginTop: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--footerPadding: var(--sp6x) 0 var(--sp4x) 0;
		--imageProportion: var(--sp8x);
		--imageWidth: var(--sp19x);
		--addressBlockMarginTop: var(--sp3x);
		--phoneBlockMarginTop: var(--sp2x);
		--socialsWrapMarginTop: var(--sp3x);
		--socialIconMarginRight: var(--sp3x);
		--euBankWidth: var(--sp14x);
		--euUnionWidth: var(--sp7x);
		--euLogosPaddingTop: var(--sp6x);
		--euLogosWrapMarginTop: var(--sp5x);

		--menuListMarginTop: var(--sp3x);
		--menuLinkMarginTop: var(--sp1-5x);
		--miniTitleMarginTop: var(--sp3x);
		--firstLinkMarginTop: var(--sp3x);
		--publicationsMarginTop: var(--sp5x);
		--mediaCenterMarginTop: var(--sp5x);
		--euBankMarginRight: var(--sp3x);

		--copyRightMarginTop: var(--sp5x);
		--supportTextMarginTop: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--footerPadding: var(--sp6x) 0 var(--sp3x) 0;
		--imageProportion: var(--sp8x);
		--imageWidth: var(--sp19x);
		--addressBlockMarginTop: 0;
		--phoneBlockMarginTop: var(--sp1-5x);
		--socialsWrapMarginTop: 0;
		--socialIconMarginRight: var(--sp3x);
		--euBankWidth: var(--sp17x);
		--euUnionWidth: var(--sp9x);
		--euLogosPaddingTop: var(--sp7x);
		--euLogosWrapMarginTop: var(--sp5x);

		--menuListMarginTop: var(--sp2x);
		--menuLinkMarginTop: var(--sp1-5x);
		--miniTitleMarginTop: var(--sp2x);
		--firstLinkMarginTop: var(--sp2x);
		--publicationsMarginTop: var(--sp4x);
		--mediaCenterMarginTop: var(--sp4x);
		--euBankMarginRight: var(--sp2x);

		--copyRightMarginTop: var(--sp5x);
		--supportTextMarginTop: var(--sp5x);

		.support-text {
			max-width: var(--sp76x);
		}

		.about-and-media-wrap {
			.address-block,
			.socials-wrap {
				margin-top: unset;
			}
		}

		.infos-cont {
			margin-top: var(--sp5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--footerPadding: var(--sp5x) 0 var(--sp2-5x) 0;
		--imageProportion: var(--sp8x);
		--imageWidth: var(--sp19x);
		--addressBlockMarginTop: 0;
		--phoneBlockMarginTop: var(--sp1-5x);
		--socialsWrapMarginTop: 0;
		--socialIconMarginRight: var(--sp3x);
		--euBankWidth: var(--sp17x);
		--euUnionWidth: var(--sp9x);
		--euLogosPaddingTop: var(--sp7x);
		--euLogosWrapMarginTop: var(--sp4x);

		--menuListMarginTop: var(--sp2x);
		--menuLinkMarginTop: var(--sp1-5x);
		--miniTitleMarginTop: var(--sp2x);
		--firstLinkMarginTop: var(--sp2x);
		--publicationsMarginTop: var(--sp5x);
		--mediaCenterMarginTop: 0;
		--euBankMarginRight: var(--sp2-5x);

		--copyRightMarginTop: var(--sp4x);
		--supportTextMarginTop: var(--sp5x);

		.support-text {
			max-width: var(--sp84x);
		}

		.about-and-media-wrap {
			margin-top: var(--sp5x);

			.address-block,
			.socials-wrap {
				margin-top: unset;
			}

			.socials-wrap {
				margin-top: var(--sp4x);
			}
		}

		.infos-cont {
			margin-top: var(--sp5x);

			.info-col {
				display: flex;
				align-items: center;

				.socials-wrap {
					margin-top: var(--sp1-5x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--footerPadding: var(--sp5x) 0;
		--imageProportion: var(--sp6x);
		--imageWidth: var(--sp14x);
		--addressBlockMarginTop: var(--sp3x);
		--phoneBlockMarginTop: var(--sp1-5x);
		--socialsWrapMarginTop: var(--sp3x);
		--socialIconMarginRight: var(--sp3x);
		--euBankWidth: var(--sp14x);
		--euUnionWidth: var(--sp8x);
		--euLogosPaddingTop: var(--sp6x);
		--euLogosWrapMarginTop: var(--sp4x);

		--menuListMarginTop: var(--sp3x);
		--menuLinkMarginTop: var(--sp1-5x);
		--miniTitleMarginTop: var(--sp1-5x);
		--firstLinkMarginTop: var(--sp3x);
		--publicationsMarginTop: var(--sp3x);
		--mediaCenterMarginTop: 0;
		--euBankMarginRight: var(--sp2x);

		--copyRightMarginTop: var(--sp4x);
		--supportTextMarginTop: var(--sp6x);

		.support-text {
			max-width: 100%;
		}

		.about-and-media-wrap {
			margin-top: var(--sp6x);

			.address-block,
			.socials-wrap {
				margin-top: unset;
			}

			.socials-wrap {
				margin-top: var(--sp4x);
			}
		}

		.infos-cont {
			margin-top: var(--sp6x);

			.info-col {
				display: flex;
				align-items: center;

				.socials-wrap {
					margin-top: var(--sp4x);
				}
			}
		}

		.address-and-phone-wrap {
			max-width: var(--sp35x);
		}

		.copyright-block {
			flex-direction: column;

			.concept-link-wrap {
				margin-top: var(--sp1-5x);
			}
		}
	}
`

export default FooterStyle
