import styled from 'styled-components'

const MenuItemStyle = styled.li`
	--menuChildrenContPadTB: var(--sp11x);
	--menuChildrenContPadLR: var(--sp11x);

	--leftInnerWrapperWidth: calc(100% - var(--rightInnerWrapperWidth));
	--rightInnerWrapperWidth: 63.64%;
	--textMaxWidth: var(--sp78x);
	--lineDistance: var(--sp7x);
	--itemDistance: var(--sp4x);
	--bottomTitleMBot: var(--sp4x);

	.flex-center-wrapper {
		position: relative;
		display: flex;
		align-items: center;

		i {
			transition: transform var(--trTime);
			pointer-events: all;
			z-index: 3;
		}

		.comp-title {
			pointer-events: all;
			padding-right: var(--sp1x);
			z-index: 3;
		}

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			background-color: transparent;
			opacity: 0;
			transition: opacity var(--trTime);
			z-index: 3;
			pointer-events: none;
			cursor: default;
		}
	}

	.note-parent-wrap {
		position: relative;
		pointer-events: all;

		.comp-title {
			transition: color var(--trTime);
		}
	}

	.menu-children-cont {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100vw;
		background-color: var(--white);
		box-shadow: 0 8px 12px -4px rgba(38, 38, 38, 0.15);
		border-top: 1px solid var(--textBlack10);
		padding: var(--menuChildrenContPadTB) var(--menuChildrenContPadLR);
		opacity: 0;
		z-index: 4;
		pointer-events: none;
		transition: opacity var(--trTime);
		cursor: default;

		.menu-children-inner-cont {
			display: flex;

			.left-inner-wrapper {
				width: var(--leftInnerWrapperWidth);

				.comp-title {
					max-width: var(--textMaxWidth);
				}
			}

			.right-inner-wrapper {
				width: var(--rightInnerWrapperWidth);

				.menu-inner-cont {
					display: flex;
					flex-wrap: wrap;
					margin: 0 calc(-1 * var(--colPadding));
					margin-bottom: calc(-1 * var(--itemDistance));

					.menu-inner-item {
						width: 33.3%;
						padding: 0 var(--colPadding);
						margin-bottom: var(--itemDistance);

						.link-item {
							.comp-title {
								transition: color var(--trTime);
							}

							@media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
								&:hover {
									.comp-title {
										color: var(--red100);
									}
								}
							}
						}

						.link-item {
							&.active-menu {
								pointer-events: none;

								.comp-title {
									color: var(--red100);
								}
							}
						}
					}
				}

				.right-inner-bottom-wrapper {
					border-top: 1px solid var(--textBlack20);
					margin-top: var(--lineDistance);
					padding-top: var(--lineDistance);

					.bottom-title {
						margin-bottom: var(--bottomTitleMBot);
						cursor: auto;
					}
				}
			}
		}
	}

	@media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		&:hover {
			.flex-center-wrapper {
				&:before {
					opacity: 1;
					pointer-events: all;
				}

				i {
					transform: rotate(180deg);
				}

				.comp-title,
				i {
					color: var(--red100);
				}
			}

			.note-parent-wrap .comp-title {
				color: var(--red100);
			}

			.menu-children-cont {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	@media not all and (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		&.opened {
			.flex-center-wrapper {
				&:before {
					opacity: 1;
					pointer-events: all;
				}

				i {
					transform: rotate(180deg);
				}

				.comp-title,
				i {
					color: var(--red100);
				}
			}

			.note-parent-wrap .comp-title {
				color: var(--red100);
			}

			.menu-children-cont {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	&.active {
		box-shadow: inset 0px -2px 0px var(--red100);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--menuChildrenContPadTB: var(--sp8x);
		--menuChildrenContPadLR: var(--sp8x);

		--rightInnerWrapperWidth: 62.3%;
		--textMaxWidth: var(--sp61x);
		--lineDistance: var(--sp5x);
		--itemDistance: var(--sp3x);
		--bottomTitleMBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--menuChildrenContPadTB: var(--sp6x);
		--menuChildrenContPadLR: var(--sp6x);

		--rightInnerWrapperWidth: 65%;
		--textMaxWidth: var(--sp43x);
		--lineDistance: var(--sp5x);
		--itemDistance: var(--sp3x);
		--bottomTitleMBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--menuChildrenContPadTB: var(--sp6x);
		--menuChildrenContPadLR: var(--sp6x);

		--rightInnerWrapperWidth: 62.9%;
		--textMaxWidth: var(--sp45x);
		--lineDistance: var(--sp4x);
		--itemDistance: var(--sp2-5x);
		--bottomTitleMBot: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--menuChildrenContPadTB: 0;
		--menuChildrenContPadLR: 0;
		--rightInnerWrapperWidth: 0;
		--textMaxWidth: 0;
		--lineDistance: 0;
		--itemDistance: 0;
		--bottomTitleMBot: 0;
	}
`

export default MenuItemStyle
