import { createGlobalStyle } from 'styled-components'
import theme from './theme'
import localFont from '@next/font/local'

export const montserrat = localFont({
    src: [
        {
            path: '../public/fonts/montserrat-regular.woff',
            weight: '400',
            display: 'swap',
            style: 'normal',
        },
        {
            path: '../public/fonts/montserrat-medium.woff',
            weight: '500',
            display: 'swap',
            style: 'normal',
        },
        {
            path: '../public/fonts/montserrat-semiBold.woff',
            weight: '600',
            display: 'swap',
            style: 'normal',
        },
    ],
})

function spaceGenerator() {
    let str = ''

    for (let i = 1; i <= 250; i++) {
        str += `--sp${i}x: calc(var(--spSize) * ${i});`
    }

    return str
}

function colorVariablesGenerator() {
    const colorsArr = Object.keys(theme.colors)

    let colorVariable = ''

    for (let i = 0; i < colorsArr.length; i++) {
        colorVariable += `--${colorsArr[i]}: ${theme.colors[colorsArr[i]]};`
    }

    return colorVariable
}

const Variables = createGlobalStyle`
    :root {
        //! Screen Sizes
        --desktopSizeXL:${(props) => props.theme.mediaQuery.DesktopSizeXL};
        --desktopSizeL:${(props) => props.theme.mediaQuery.DesktopSizeL};
        --desktopSizeM:${(props) => props.theme.mediaQuery.DesktopSizeM};
        --desktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
        --desktopSizeXS: ${(props) => props.theme.mediaQuery.DesktopSizeXS};
        --tabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
        --tabletSizeS: ${(props) => props.theme.mediaQuery.TabletSizeS};
        --mobileSize: ${(props) => props.theme.mediaQuery.MobileSize};
        --mobileSizeS: ${(props) => props.theme.mediaQuery.MobileSizeS};

        //! Fonts
        --montserrat: ${montserrat.style.fontFamily};

        //! Spacing
        --spSize: 8px;

        --sp0-5x: calc(var(--spSize) * 0.5);
        --sp1-5x: calc(var(--spSize) * 1.5);
        --sp2-5x: calc(var(--spSize) * 2.5);
        ${spaceGenerator()}

        //! Transition
        --trTime: 0.3s;

        //! Line heights
        --lineHeight1: 1.5;
        --lineHeight2: 1.4;

        //! Colors
        ${colorVariablesGenerator()}
    }

    @media only screen and  (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
        :root {
            --h1XL: 184px;
            --h1L: 120px;
            --h1: 88px;
            --h2: 72px;
            --h3: 64px;
            --h4: 48px;
            --h5: 40px;
            --h6: 32px;
            --p1: 24px;
            --p2: 20px;
            --p3: 18px;
            --p4: 14px;

            //! Line height
            --lineHeightH1: var(--lineHeight2);
            --lineHeightH2: var(--lineHeight2);
            --lineHeightH3: var(--lineHeight2);
            --lineHeightH4: var(--lineHeight1);
            --lineHeightH5: var(--lineHeight1);
            --lineHeightH6: var(--lineHeight1);
            --lineHeightP1: var(--lineHeight1);
            --lineHeightP2: var(--lineHeight1);
            --lineHeightP3: var(--lineHeight1);
            --lineHeightP4: var(--lineHeight1);
            
            //! Border radius
            --radiusL: var(--sp4x);
            --radiusM: var(--sp3x);
            --radiusS: var(--sp1-5x);

            //! Distances
            --distanceL1: var(--sp25x);
            --distanceL2: var(--sp20x);
            --distanceL3: var(--sp18x);
            --distanceM1: var(--sp15x);
            --distanceM2: var(--sp13x);
            --distanceM3: var(--sp10x);
            --distanceS1: var(--sp8x);
            --distanceS2: var(--sp6x);
            --distanceS3: var(--sp4x);

            //! Section distance
            --sectionDistance: var(--sp23x);

            //! Grid distance
            --colPadding: var(--sp2-5x);
            
            //! Container padding
            --contPaddingLR: var(--sp33x);

            //! Header height
            --headerHeightSize: var(--sp16x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        :root {
            --h1XL: 144px;
            --h1L: 104px;
            --h1: 72px;
            --h2: 56px;
            --h3: 48px;
            --h4: 40px;
            --h5: 32px;
            --h6: 24px;
            --p1: 20px;
            --p2: 18px;
            --p3: 16px;
            --p4: 12px;

            //! Line height
            --lineHeightH1: var(--lineHeight2);
            --lineHeightH2: var(--lineHeight1);
            --lineHeightH3: var(--lineHeight1);
            --lineHeightH4: var(--lineHeight1);
            --lineHeightH5: var(--lineHeight1);
            --lineHeightH6: var(--lineHeight1);
            --lineHeightP1: var(--lineHeight1);
            --lineHeightP2: var(--lineHeight1);
            --lineHeightP3: var(--lineHeight1);
            --lineHeightP4: var(--lineHeight1);

            //! Border radius
            --radiusL: var(--sp3x);
            --radiusM: var(--sp2x);
            --radiusS: var(--sp1x);

            //! Distances
            --distanceL1: var(--sp20x);
            --distanceL2: var(--sp18x);
            --distanceL3: var(--sp15x);
            --distanceM1: var(--sp13x);
            --distanceM2: var(--sp10x);
            --distanceM3: var(--sp8x);
            --distanceS1: var(--sp6x);
            --distanceS2: var(--sp4x);
            --distanceS3: var(--sp3x);

            //! Section distance
            --sectionDistance: var(--sp20x);

            //! Grid distance
            --colPadding: var(--sp2x);

            //! Container padding
            --contPaddingLR: var(--sp25x);

            //! Header height
            --headerHeightSize: var(--sp11x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
        :root {
            --h1XL: 120px;
            --h1L: 88px;
            --h1: 56px;
            --h2: 48px;
            --h3: 40px;
            --h4: 32px;
            --h5: 24px;
            --h6: 20px;
            --p1: 18px;
            --p2: 16px;
            --p3: 16px;
            --p4: 12px;

            //! Line height
            --lineHeightH1: var(--lineHeight1);
            --lineHeightH2: var(--lineHeight1);
            --lineHeightH3: var(--lineHeight1);
            --lineHeightH4: var(--lineHeight1);
            --lineHeightH5: var(--lineHeight1);
            --lineHeightH6: var(--lineHeight1);
            --lineHeightP1: var(--lineHeight1);
            --lineHeightP2: var(--lineHeight1);
            --lineHeightP3: var(--lineHeight1);
            --lineHeightP4: var(--lineHeight1);

            //! Border radius
            --radiusL: var(--sp3x);
            --radiusM: var(--sp2x);
            --radiusS: var(--sp1x);

            //! Distances
            --distanceL1: var(--sp18x);
            --distanceL2: var(--sp15x);
            --distanceL3: var(--sp13x);
            --distanceM1: var(--sp11x);
            --distanceM2: var(--sp9x);
            --distanceM3: var(--sp7x);
            --distanceS1: var(--sp5x);
            --distanceS2: var(--sp3x);
            --distanceS3: var(--sp2x);

            //! Section distance
            --sectionDistance: var(--sp16x);

            //! Grid distance
            --colPadding: var(--sp2x);

            //! Container padding
            --contPaddingLR: var(--sp11x);

            //! Header height
            --headerHeightSize: var(--sp10x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        :root {
            --h1XL: 104px;
            --h1L: 72px;
            --h1: 48px;
            --h2: 40px;
            --h3: 32px;
            --h4: 28px;
            --h5: 24px;
            --h6: 20px;
            --p1: 18px;
            --p2: 16px;
            --p3: 14px;
            --p4: 12px;

            //! Line height
            --lineHeightH1: var(--lineHeight1);
            --lineHeightH2: var(--lineHeight1);
            --lineHeightH3: var(--lineHeight1);
            --lineHeightH4: var(--lineHeight1);
            --lineHeightH5: var(--lineHeight1);
            --lineHeightH6: var(--lineHeight1);
            --lineHeightP1: var(--lineHeight1);
            --lineHeightP2: var(--lineHeight1);
            --lineHeightP3: var(--lineHeight1);
            --lineHeightP4: var(--lineHeight1);

            //! Border radius
            --radiusL: var(--sp3x);
            --radiusM: var(--sp2x);
            --radiusS: var(--sp1x);

            //! Distances
            --distanceL1: var(--sp17x);
            --distanceL2: var(--sp14x);
            --distanceL3: var(--sp13x);
            --distanceM1: var(--sp10x);
            --distanceM2: var(--sp8x);
            --distanceM3: var(--sp6x);
            --distanceS1: var(--sp4x);
            --distanceS2: var(--sp3x);
            --distanceS3: var(--sp2x);

            //! Section distance
            --sectionDistance: var(--sp15x);

            //! Grid distance
            --colPadding: var(--sp2x);

            //! Container padding
            --contPaddingLR: var(--sp10x);

            //! Header height
            --headerHeightSize: var(--sp9x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        :root {
            --h1XL: 96px;
            --h1L: 64px;
            --h1: 40px;
            --h2: 32px;
            --h3: 28px;
            --h4: 24px;
            --h5: 20px;
            --h6: 18px;
            --p1: 16px;
            --p2: 16px;
            --p3: 14px;
            --p4: 12px;

            //! Line height
            --lineHeightH1: var(--lineHeight1);
            --lineHeightH2: var(--lineHeight1);
            --lineHeightH3: var(--lineHeight1);
            --lineHeightH4: var(--lineHeight1);
            --lineHeightH5: var(--lineHeight1);
            --lineHeightH6: var(--lineHeight1);
            --lineHeightP1: var(--lineHeight1);
            --lineHeightP2: var(--lineHeight1);
            --lineHeightP3: var(--lineHeight1);
            --lineHeightP4: var(--lineHeight1);

            //! Border radius
            --radiusL: var(--sp3x);
            --radiusM: var(--sp2x);
            --radiusS: var(--sp1x);

            //! Distances
            --distanceL1: var(--sp14x);
            --distanceL2: var(--sp13x);
            --distanceL3: var(--sp10x);
            --distanceM1: var(--sp8x);
            --distanceM2: var(--sp6x);
            --distanceM3: var(--sp6x);
            --distanceS1: var(--sp4x);
            --distanceS2: var(--sp3x);
            --distanceS3: var(--sp2x);

            //! Section distance
            --sectionDistance: var(--sp13x);

            //! Grid distance
            --colPadding: var(--sp1-5x);

            //! Container padding
            --contPaddingLR: var(--sp8x);

            //! Header height
            --headerHeightSize: var(--sp9x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        :root {
            --h1XL: 56px;
            --h1L: 56px;
            --h1: 40px;
            --h2: 32px;
            --h3: 28px;
            --h4: 24px;
            --h5: 20px;
            --h6: 18px;
            --p1: 16px;
            --p2: 16px;
            --p3: 14px;
            --p4: 12px;

            //! Line height
            --lineHeightH1: var(--lineHeight1);
            --lineHeightH2: var(--lineHeight1);
            --lineHeightH3: var(--lineHeight1);
            --lineHeightH4: var(--lineHeight1);
            --lineHeightH5: var(--lineHeight1);
            --lineHeightH6: var(--lineHeight1);
            --lineHeightP1: var(--lineHeight1);
            --lineHeightP2: var(--lineHeight1);
            --lineHeightP3: var(--lineHeight1);
            --lineHeightP4: var(--lineHeight1);

             //! Border radius
            --radiusL: var(--sp3x);
            --radiusM: var(--sp2x);
            --radiusS: var(--sp1x);

            //! Distances
            --distanceL1: var(--sp13x);
            --distanceL2: var(--sp10x);
            --distanceL3: var(--sp8x);
            --distanceM1: var(--sp6x);
            --distanceM2: var(--sp6x);
            --distanceM3: var(--sp4x);
            --distanceS1: var(--sp3x);
            --distanceS2: var(--sp2x);
            --distanceS3: var(--sp1x);

            //! Section distance
            --sectionDistance: var(--sp12x);

            //! Grid distance
            --colPadding: var(--sp1-5x);

            //! Container padding
            --contPaddingLR: var(--sp6x);

            //! Header height
            --headerHeightSize: var(--sp8x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        :root {
            --h1XL: 56px;
            --h1L: 56px;
            --h1: 40px;
            --h2: 32px;
            --h3: 28px;
            --h4: 24px;
            --h5: 20px;
            --h6: 18px;
            --p1: 16px;
            --p2: 16px;
            --p3: 14px;
            --p4: 12px;

            //! Line height
            --lineHeightH1: var(--lineHeight1);
            --lineHeightH2: var(--lineHeight1);
            --lineHeightH3: var(--lineHeight1);
            --lineHeightH4: var(--lineHeight1);
            --lineHeightH5: var(--lineHeight1);
            --lineHeightH6: var(--lineHeight1);
            --lineHeightP1: var(--lineHeight1);
            --lineHeightP2: var(--lineHeight1);
            --lineHeightP3: var(--lineHeight1);
            --lineHeightP4: var(--lineHeight1);

            //! Border radius
            --radiusL: var(--sp3x);
            --radiusM: var(--sp2x);
            --radiusS: var(--sp1x);

            //! Distances
            --distanceL1: var(--sp13x);
            --distanceL2: var(--sp10x);
            --distanceL3: var(--sp8x);
            --distanceM1: var(--sp6x);
            --distanceM2: var(--sp6x);
            --distanceM3: var(--sp4x);
            --distanceS1: var(--sp3x);
            --distanceS2: var(--sp2x);
            --distanceS3: var(--sp1x);

            //! Section distance
            --sectionDistance: var(--sp12x);

            //! Grid distance
            --colPadding: var(--sp1-5x);

            //! Container padding
            --contPaddingLR: var(--sp6x);

            //! Header height
            --headerHeightSize: var(--sp8x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
        :root {
            --h1XL: 32px;
            --h1L: 48px;
            --h1: 32px;
            --h2: 24px;
            --h3: 24px;
            --h4: 20px;
            --h5: 20px;
            --h6: 16px;
            --p1: 16px;
            --p2: 16px;
            --p3: 14px;
            --p4: 12px;

            //! Line height
            --lineHeightH1: var(--lineHeight1);
            --lineHeightH2: var(--lineHeight1);
            --lineHeightH3: var(--lineHeight1);
            --lineHeightH4: var(--lineHeight1);
            --lineHeightH5: var(--lineHeight1);
            --lineHeightH6: var(--lineHeight1);
            --lineHeightP1: var(--lineHeight1);
            --lineHeightP2: var(--lineHeight1);
            --lineHeightP3: var(--lineHeight1);
            --lineHeightP4: var(--lineHeight1);

            //! Border radius
            --radiusL: var(--sp3x);
            --radiusM: var(--sp2x);
            --radiusS: var(--sp1x);

            //! Distances
            --distanceL1: var(--sp10x);
            --distanceL2: var(--sp8x);
            --distanceL3: var(--sp6x);
            --distanceM1: var(--sp6x);
            --distanceM2: var(--sp4x);
            --distanceM3: var(--sp3x);
            --distanceS1: var(--sp2x);
            --distanceS2: var(--sp1x);
            --distanceS3: var(--sp1x);

            //! Section distance
            --sectionDistance: var(--sp8x);

            //! Grid distance
            --colPadding: var(--sp1x);

            //! Container padding
            --contPaddingLR: var(--sp2x);

            //! Header height
            --headerHeightSize: var(--sp8x);
        }
    }
`

export default Variables
