export default {
    routes: {
        home: {
            path: '/',
            slug: 'home',
            name: 'home',
            autoload: true,
            transparentHeader: false,
        },

        //! Why Armenia
        generalOverview: {
            path: '/why-armenia/general-overview',
            slug: 'general-overview',
            name: 'overview',
            autoload: true,
            isWhyArmeniaFooterMenu: true,
            transparentHeader: false,
        },

        livingInArmenia: {
            path: '/why-armenia/living-in-armenia',
            slug: 'living-in-armenia',
            name: 'livingInArmenia',
            autoload: true,
            isWhyArmeniaFooterMenu: true,
            transparentHeader: false,
        },

        talents: {
            path: '/why-armenia/talents',
            slug: 'talent',
            name: 'talents',
            autoload: true,
            isWhyArmeniaFooterMenu: true,
            transparentHeader: false,
        },

        infrastructure: {
            path: '/why-armenia/infrastructure',
            slug: 'infrastructure',
            name: 'infrastructures',
            autoload: true,
            isWhyArmeniaFooterMenu: true,
            transparentHeder: false,
        },

        investmentMapOfArmenia: {
            path: '/why-armenia/investment-map-of-armenia',
            slug: 'investment-map',
            name: 'investmentMapOfArmenia',
            autoload: true,
            isWhyArmeniaFooterMenu: true,
            transparentHeder: false,
        },

        businessRegistrationAndTypes: {
            path: '/why-armenia/business-registration-and-types',
            slug: 'business-registration-and-type',
            name: 'businessRegistrationAndTypes',
            autoload: true,
            isBusinessEnvironmentFooterMenu: true,
            transparentHeder: false,
        },

        taxationSystem: {
            path: '/why-armenia/taxation-system',
            slug: 'taxation-system',
            name: 'taxationSystem',
            autoload: true,
            isBusinessEnvironmentFooterMenu: true,
            transparentHeder: false,
        },

        investmentLegislation: {
            path: '/why-armenia/investment-legislation',
            slug: 'investment-legislation',
            name: 'investmentLegislation',
            autoload: true,
            isBusinessEnvironmentFooterMenu: true,
            transparentHeder: false,
        },

        macroeconomicCondition: {
            path: '/why-armenia/macroeconomic-condition',
            slug: 'macroeconomic-condition',
            name: 'macroeconomicCondition',
            autoload: true,
            isBusinessEnvironmentFooterMenu: true,
            transparentHeder: false,
        },

        marketAndForeignTrade: {
            path: '/why-armenia/market-and-foreign-trade',
            slug: 'market-and-foreign-trade',
            name: 'marketAndForeignTrade',
            autoload: true,
            isBusinessEnvironmentFooterMenu: true,
            transparentHeder: false,
        },

        //! Business Opportunities
        incentives: {
            path: '/business-opportunities/incentives/tax-and-customs-incentives',
            slug: 'tax-and-customs-incentive',
            name: 'incentives',
            autoload: true,
            transparentHeder: false,
        },

        //! Incentives sub menus
        taxAndCustomsIncentives: {
            path: '/business-opportunities/incentives/tax-and-customs-incentives',
            slug: 'tax-and-customs-incentive',
            name: 'taxAndCustomsIncentives',
            autoload: true,
            type: 'incentive',
            transparentHeder: false,
        },

        freeEconomicZones: {
            path: '/business-opportunities/incentives/free-economic-zones',
            slug: 'free-economic-zone',
            name: 'freeEconomicZones',
            autoload: true,
            type: 'incentive',
            transparentHeder: false,
        },

        stateSupportPrograms: {
            path: '/business-opportunities/incentives/state-support-programs',
            slug: 'state-support-program',
            name: 'stateSupportPrograms',
            autoload: true,
            type: 'incentive',
            transparentHeder: false,
        },

        financialIncentivesAI: {
            path: '/business-opportunities/incentives/financial-incentives-for-agriculture-and-industry',
            slug: 'financial-incentives-a-i',
            name: 'financialIncentivesAI',
            autoload: true,
            type: 'incentive',
            transparentHeder: false,
        },

        //! Sectors
        sector: {
            path: '/business-opportunities/sector/:slug',
            slug: 'sector',
            name: 'sector',
            singleType: 'sector',
            autoload: true,
            transparentHeder: false,
        },

        //! About Us
        boardOfTrustees: {
            path: '/about-us/board-of-trustees',
            slug: 'trustee',
            name: 'boardOfTrustees',
            autoload: true,
            isAboutUsFooterMenu: true,
            transparentHeader: false,
        },

        ourServices: {
            path: '/about-us/our-services',
            slug: 'service',
            name: 'ourServices',
            autoload: true,
            isAboutUsFooterMenu: true,
            transparentHeader: false,
        },

        ourTeam: {
            path: '/about-us/our-team',
            slug: 'team',
            name: 'ourTeam',
            autoload: true,
            isAboutUsFooterMenu: true,
            transparentHeader: true,
        },

        networkOfRepresentatives: {
            path: '/about-us/network-of-representatives',
            slug: 'network-of-representative',
            name: 'networkOfRepresentatives',
            autoload: true,
            isAboutUsFooterMenu: true,
            transparentHeader: false,
        },

        career: {
            path: '/about-us/career',
            slug: 'careers-page',
            name: 'career',
            postType: {
                slug: 'careers',
                name: 'careers',
                queryParamKeys: [],
            },
            autoload: true,
            isAboutUsFooterMenu: true,
            transparentHeader: false,
        },

        singleCareer: {
            path: '/about-us/career/:slug',
            slug: 'careers',
            name: 'careers',
            singleType: 'careers',
            autoload: true,
            transparentHeader: false,
        },

        ourMission: {
            path: '/about-us/our-mission',
            slug: 'mission',
            name: 'ourMission',
            autoload: true,
            transparentHeader: false,
        },

        //! Media
        news: {
            path: '/media/news',
            slug: 'news-page',
            name: 'news',
            autoload: true,
            isMediaCenterFooterMenu: true,
            transparentHeader: false,
            postType: {
                slug: 'news',
                name: 'news',
                queryParamKeys: ['page', 'catId'],
            },
        },

        singleNews: {
            path: '/media/news/:slug',
            slug: 'news',
            name: 'singleNews',
            singleType: 'singleNews',
            autoload: true,
            transparentHeader: false,
        },

        events: {
            path: '/media/events',
            slug: 'events-page',
            name: 'events',
            autoload: true,
            isMediaCenterFooterMenu: true,
            transparentHeader: false,
            postType: {
                slug: 'events',
                name: 'events',
                queryParamKeys: ['page', 'catId'],
            },
        },

        singleEvent: {
            path: '/media/events/:slug',
            slug: 'events',
            name: 'singleEvent',
            singleType: 'singleEvent',
            autoload: true,
            transparentHeader: false,
        },

        //! Publications
        publications: {
            path: '/publications',
            slug: 'publication-page',
            name: 'publications',
            autoload: true,
            transparentHeader: false,
            postType: {
                slug: 'publications',
                name: 'publications',
                queryParamKeys: ['page'],
            },
        },

        //! Contacts
        contacts: {
            path: '/contacts',
            slug: 'contact',
            name: 'contacts',
            autoload: true,
            transparentHeader: false,
        },
    },
}
