//* Helper
import config from 'helpers/_config'

//! Header menu items
const menuData = [
	{
		name: 'whyArmenia',
		page: 'why-armenia',
		text: 'whyArmeniaDesc',
		children: {
			top: {
				items: [
					{
						name: 'overview',
						path: config.routes.generalOverview.path,
					},
					{
						name: 'talents',
						path: config.routes.talents.path,
					},
					{
						name: 'investmentMapOfArmenia',
						path: config.routes.investmentMapOfArmenia.path,
					},
					{
						name: 'livingInArmenia',
						path: config.routes.livingInArmenia.path,
					},
					{
						name: 'infrastructures',
						path: config.routes.infrastructure.path,
					},
				],
			},
			bottom: {
				title: 'businessEnvironment',
				items: [
					{
						name: 'businessRegistrationAndTypes',
						path: config.routes.businessRegistrationAndTypes.path,
					},
					{
						name: 'investmentLegislation',
						path: config.routes.investmentLegislation.path,
					},
					{
						name: 'macroeconomicCondition',
						path: config.routes.macroeconomicCondition.path,
					},
					{
						name: 'taxationSystem',
						path: config.routes.taxationSystem.path,
					},
					{
						name: 'marketAndForeignTrade',
						path: config.routes.marketAndForeignTrade.path,
					},
				],
			},
		},
	},

	{
		name: 'businessOpportunities',
		page: 'business-opportunities',
		text: 'businessOpportunitiesDesc',
		children: {
			top: {
				items: [
					{
						name: 'incentives',
						path: config.routes.incentives.path,
					},
				],
			},
			bottom: {
				title: 'sectors',
				items: [], //! From global data
			},
		},
	},

	{
		name: 'publications',
		path: '/publications',
	},

	{
		name: 'aboutUs',
		page: 'about-us',
		text: 'aboutUsDesc',
		children: {
			top: {
				items: [
					{
						name: 'ourMission',
						path: config.routes.ourMission.path,
					},
					{
						name: 'ourServices',
						path: config.routes.ourServices.path,
					},
					{
						name: 'ourTeam',
						path: config.routes.ourTeam.path,
					},
					{
						name: 'boardOfTrustees',
						path: config.routes.boardOfTrustees.path,
					},
					{
						name: 'networkOfRepresentatives',
						path: config.routes.networkOfRepresentatives.path,
					},
					{
						name: 'career',
						path: config.routes.career.path,
					},
				],
			},
		},
	},

	{
		name: 'media',
		page: 'media',
		text: 'mediaDesc',
		children: {
			top: {
				items: [
					{
						name: 'news',
						path: config.routes.news.path,
					},
					{
						name: 'events',
						path: config.routes.events.path,
					},
				],
			},
		},
	},

	{
		name: 'contacts',
		path: '/contacts',
	},
]

export default menuData
