import { memo as Memo, useMemo } from 'react'

//* Component
import MenuItem from './MenuItem'

//* Style
import DesktopMenuStyle from './style'

const DesktopMenu = Memo(({ menuData, className }) => {
    //! Menu Items
    const menuItemsHtml = useMemo(() => {
        return (
            <ul className='menu-cont'>
                {Object.values(menuData).map((menuItem, k) => (
                    <MenuItem
                        key={k}
                        {...menuItem}
                    />
                ))}
            </ul>
        )
    }, [menuData])

    return <DesktopMenuStyle className={`desktop-menu ${className || ''}`}>{menuItemsHtml}</DesktopMenuStyle>
})

export default DesktopMenu
