//* Provider's
import LanguageProvider from './providers/LanguageProvider'
import UIProvider from './providers/UIProvider'
import DataProvider from './providers/DataProvider'

//* ConsumerHOC's
import withLanguageContext from './consumerHOC/LanguageConsumer'
import withUIContext from './consumerHOC/UIConsumer'
import withDataContext from './consumerHOC/DataConsumer'

export {
    LanguageProvider,
    withLanguageContext,
    UIProvider,
    withUIContext,
    DataProvider,
    withDataContext
}
