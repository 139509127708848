import { memo as Memo, useEffect, useRef, useCallback, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import gsap from 'gsap'

//* HOC
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import Text from 'components/common/Text'
import CustomLink from 'components/common/CustomLink'

//* Style
import MenuItemCollapseStyle from './style'

const MenuItemCollapse = Memo(({ name, path, children, isBurgerMenuOpen }) => {
	//! Router
	const router = useRouter()

	//! Refs
	const collapseRef = useRef()
	const bodyRef = useRef()

	//! State
	const [open, setOpen] = useState()

	//! Window Click Listener for close
	const handleWindowClick = useCallback(
		(e) => {
			if (!collapseRef.current.contains(e.target)) {
				setOpen(false)
			}
		},
		[open]
	)

	//! Did Mount
	useEffect(() => {
		if (isBurgerMenuOpen && bodyRef.current !== undefined) {
			gsap.set(bodyRef.current, { height: 0 })
		}

		window.addEventListener('click', handleWindowClick)

		return () => {
			window.removeEventListener('click', handleWindowClick)
		}
	}, [isBurgerMenuOpen])

	//! Toggle Body
	useEffect(() => {
		if (bodyRef.current !== undefined) {
			gsap.to(bodyRef.current, { duration: 0.3, height: open ? 'auto' : 0 })
		}
	}, [open])

	//! Handle Collapse Click
	const handleCollapseClick = useCallback(() => {
		setOpen(!open)
	}, [open])

	const collapseMenuItemStore = (items) => {
		return (
			<ul className='menu-inner-cont'>
				{items?.map((item, key) => (
					<li
						key={key}
						className={`menu-inner-item`}
					>
						<CustomLink
							url={item.path}
							className={`${
								router.pathname === item.path ||
								(router.asPath.split('/')[2] === item.path.split('/')[2] && router.asPath.split('/')[2] !== 'sector') ||
								(router.asPath.split('/')[3] === item.path.split('/')[3] && router.asPath.split('/')[2] === 'sector')
									? 'active-menu'
									: ''
							}`}
						>
							<Text
								tag={`span`}
								className={`p-2 font-montserrat textBlack-100`}
								text={item.name}
							/>
						</CustomLink>
					</li>
				))}
			</ul>
		)
	}

	const collapseContentStore = useMemo(() => {
		return (
			children && (
				<>
					<div className={`body-top-wrapper`}>{collapseMenuItemStore(children.top.items)}</div>

					{children.bottom && (
						<div className={`body-bottom-wrapper`}>
							<Text
								tag={`p`}
								className={`p-2 font-montserrat textBlack-40 capitalize body-bottom-title`}
								text={children.bottom.title}
							/>

							{collapseMenuItemStore(children.bottom.items)}
						</div>
					)}
				</>
			)
		)
	}, [children, router])

	return (
		<MenuItemCollapseStyle
			ref={collapseRef}
			className={`menu-item-collapse ${open ? 'active' : ''}`}
		>
			{path ? (
				<CustomLink url={path}>
					<Text
						tag={`h4`}
						className={`not-parent font-montserrat h4 textBlack-100 ${router.pathname === path ? 'active-page' : ''}`}
						text={name}
					/>
				</CustomLink>
			) : (
				<>
					<div
						className={`collapse-header`}
						onClick={handleCollapseClick}
					>
						<Text
							tag={`h4`}
							className={`font-montserrat h4 textBlack-100`}
							text={name}
						/>

						<span className={`toggle-icon`} />
					</div>

					<div
						ref={bodyRef}
						className={`collapse-body-cont`}
					>
						<div className={`collapse-body`}>{collapseContentStore}</div>
					</div>
				</>
			)}
		</MenuItemCollapseStyle>
	)
})

export default withUIContext(MenuItemCollapse, ['isBurgerMenuOpen'])
