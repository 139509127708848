import styled from 'styled-components'

const BurgerMenuStyle = styled.section`
	display: none;

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--burgerInnerContPadT: var(--sp6x);
		--burgerInnerContPadLR: var(--sp8x);
		--burgerInnerContPadB: var(--sp17x);

		display: unset;
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 4;
		transition: transform var(--trTime);
		transform: translate3d(100%, 0, 0);
		background-color: var(--bgGray);

		&.active {
			transform: translate3d(0, 0, 0);
		}

		.burger-menu-cont {
			margin-top: var(--headerHeightSize);
			height: 100%;

			.burger-menu-inner-cont {
				height: 100%;
				padding: var(--burgerInnerContPadT) var(--burgerInnerContPadLR) var(--burgerInnerContPadB);

				overflow-y: scroll;
				scrollbar-width: none; /* Firefox */
				-ms-overflow-style: none; /* IE and Edge */

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
			--burgerInnerContPadLR: var(--sp6x);

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
				--burgerInnerContPadLR: var(--sp2x);
			}
		}
	}
`

export default BurgerMenuStyle
