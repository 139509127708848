import { forwardRef, useId } from 'react'
import Link from 'next/link'

//* HOC's
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

const CustomLink = forwardRef(({ selectedLang, options, target, ...props }, ref) => {
	const id = useId()

	return (
		<Link
			scroll={props.scroll}
			shallow={props.shallow}
			href={props.url}
			ref={ref}
			id={id}
			rel={props.rel}
			className={`link-item ${props.className || ''}`}
			aria-label={props.ariaLabel}
			onMouseOver={props.onMouseOver}
			onMouseOut={props.onMouseOut}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			onClick={props.onClick}
			target={target}
			{...options}
		>
			{props.content || props.children}
		</Link>
	)
})

CustomLink.displayName = 'CustomLink'

export default withLanguageContext(CustomLink, ['selectedLang'])
