import styled from 'styled-components'

const HeaderStyle = styled.header`
	--headerContentPadTB: var(--sp3x);
	--headerContentPadLR: var(--sp11x);
	--langIconWrapMLeft: var(--sp8x);

	--titleWrapPadding: var(--sp4x) var(--sp11x);
	--sectionTitleMarginRight: var(--sp8x);

	--beforePosition: calc(100% + var(--sp4x) - 2px);

	position: fixed;
	top: 0;
	width: 100%;
	background-color: var(--white);
	z-index: 7;

	&.second-header-active {
		background-color: transparent;
	}

	.titles-wrap {
		overflow-y: scroll;
		padding-right: unset;

		&::-webkit-scrollbar {
			display: none;
			-ms-overflow-style: none;
			scrollbar-width: none;
		}

		.titles-inner-wrap {
			display: flex;
			width: 100%;
			white-space: nowrap;

			&:last-child {
				.section-title-wrap {
					padding-right: var(--sectionTitleMarginRight);
				}
			}
		}
	}

	.header-content-wrap {
		position: relative;
		display: flex;
		justify-content: space-between;
		padding: 0 var(--headerContentPadLR);
		border-bottom: 1px solid var(--textBlack10);
		transition: var(--trTime);

		.logo-wrapper {
			margin: var(--headerContentPadTB) 0;
		}

		&.hide {
			transform: translateY(-100%);
		}

		.right-flex-wrapper {
			display: flex;
			align-items: center;

			.language-icon-wrapper {
				margin-left: var(--langIconWrapMLeft);
			}

			.burger-menu-icon-wrap {
				display: none;
			}

			.desktop-menu {
				height: 100%;
				display: flex;
				align-items: center;

				.menu-cont {
					height: 100%;
					display: flex;
					align-items: center;

					.menu-item {
						height: 100%;
						display: flex;
						align-items: center;
						pointer-events: none;

						.flex-center-wrapper {
							height: 100%;
						}
					}
				}
			}
		}
	}

	.titles-wrap {
		padding: var(--titleWrapPadding);
		background-color: var(--bgGray);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		transition: var(--trTime);

		&.hide {
			top: -100%;
			left: 0;
		}

		.titles-inner-wrap {
			display: flex;

			.section-title-wrap {
				&.active {
					.section-title {
						pointer-events: none;
						color: var(--textBlack100);

						&::before {
							opacity: 1;
						}
					}
				}

				.section-title {
					margin-right: var(--sectionTitleMarginRight);
					color: var(--textBlack60);
					position: relative;
					cursor: pointer;

					&::before {
						content: '';
						position: absolute;
						top: var(--beforePosition);
						left: 0;
						width: 100%;
						background-color: var(--red100);
						height: 2px;
						opacity: 0;
					}

					&:last-child {
						margin-right: unset;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--headerContentPadTB: var(--sp2x);
		--headerContentPadLR: var(--sp8x);
		--langIconWrapMLeft: var(--sp6x);

		--titleWrapPadding: var(--sp3x) var(--sp8x);
		--sectionTitleMarginRight: var(--sp6x);

		--beforePosition: calc(100% + var(--sp3x) - 2px);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--headerContentPadTB: var(--sp2x);
		--headerContentPadLR: var(--sp6x);
		--langIconWrapMLeft: var(--sp5x);

		--titleWrapPadding: var(--sp3x) var(--sp6x);
		--sectionTitleMarginRight: var(--sp5x);
		--beforePosition: calc(100% + var(--sp3x) - 2px);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--headerContentPadTB: var(--sp2x);
		--headerContentPadLR: var(--sp6x);
		--langIconWrapMLeft: var(--sp4x);

		--titleWrapPadding: var(--sp2-5x) var(--sp6x);
		--sectionTitleMarginRight: var(--sp4x);

		--beforePosition: calc(100% + var(--sp2-5x) - 2px);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--titleWrapPadding: var(--sp2x) var(--sp6x);
		--sectionTitleMarginRight: var(--sp4x);

		--beforePosition: calc(100% + var(--sp2x) - 2px);

		.header-content-wrap .right-flex-wrapper .desktop-menu {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--titleWrapPadding: var(--sp2x) var(--sp6x);
		--sectionTitleMarginRight: var(--sp4x);

		--beforePosition: calc(100% + var(--sp2x) - 2px);

		.titles-wrap {
			overflow: scroll hidden;
			padding-right: unset;

			&::-webkit-scrollbar {
				display: none;
				-ms-overflow-style: none;
				scrollbar-width: none;
			}

			.titles-inner-wrap {
				display: flex;
				width: 100%;
				white-space: nowrap;

				.section-title {
					&:last-child {
						padding-right: var(--sectionTitleMarginRight);
					}
				}
			}
		}

		.header-content-wrap .right-flex-wrapper .desktop-menu {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--titleWrapPadding: var(--sp2x) var(--sp6x);
		--sectionTitleMarginRight: var(--sp4x);

		--beforePosition: calc(100% + var(--sp2x) - 2px);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--titleWrapPadding: var(--sp2x);
		--sectionTitleMarginRight: var(--sp4x);

		--beforePosition: calc(100% + var(--sp2x) - 2px);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--headerContentPadTB: var(--sp2x);
		--headerContentPadLR: var(--sp8x);
		--langIconWrapMLeft: 0;
		--burgerMenuIconMLeft: var(--sp4x);

		.header-content-wrap .right-flex-wrapper {
			.language-icon-wrapper {
				line-height: 1;

				i {
					font-size: var(--sp4x);
				}
			}

			.burger-menu-icon-wrap {
				display: unset;
				line-height: 1;
				width: var(--sp4x);
				height: var(--sp4x);
				margin-left: var(--burgerMenuIconMLeft);
			}
		}

		.header-content-wrap .right-flex-wrapper .desktop-menu {
			display: none;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
			--headerContentPadTB: var(--sp1-5x);
			--headerContentPadLR: var(--sp6x);

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
				--headerContentPadLR: var(--sp2x);
				--burgerMenuIconMLeft: var(--sp3x);
			}
		}
	}
`

export default HeaderStyle
