import { forwardRef, useRef, useImperativeHandle } from 'react'
import Image from 'next/image'

//* Style
import ImageStyle from './style'

const CustomImage = forwardRef(({ src, alt, className, width, height, priority }, ref) => {
	const imgRef = useRef()

	useImperativeHandle(ref, () => imgRef.current, [])

	return (
		<ImageStyle
			style={{ width, height }}
			className={`image-cont ${className || ''}`}
			ref={imgRef}
		>
			<Image
				fill
				unoptimized={src && src.includes('.svg')}
				sizes='(min-width: 320px) 100%'
				src={src || '/images/default.jpeg'}
				alt={alt || 'image'}
				priority={priority}
				loading={!priority ? 'lazy' : 'eager'}
				draggable={false}
			/>
		</ImageStyle>
	)
})

CustomImage.displayName = 'CustomImage'

export default CustomImage
