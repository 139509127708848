import { createGlobalStyle } from 'styled-components'

const Typography = createGlobalStyle`
    body {
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        position: relative;
        font-family: var(--montserrat);
        color: var(--textBlack100);
        -webkit-overflow-scrolling: touch;
        ${(props) => (props.isBurgerMenuOpen || props.popupIsOpen ? 'overflow: hidden;' : ' overflow-x: hidden;')}
        ${(props) =>
        props.isBurgerMenuOpen || props.isPopupOpen
            ? ` 
                    touch-action: none;
                    -ms-touch-action: none;
                `
            : ``}
        
        main {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            z-index: 1;
            transition: margin-top 1s;
        }
        
        #__next {
            min-height: 100vh;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &.hidden {
            overflow: hidden;
            max-height: 100vh;
        }
    }

    ul,
    fieldset {
        margin: 0;
        padding: 0;
        list-style: none;
        border: none;
    }

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }

    button{
        padding: 0;
        border-width: 0;
        border-color: inherit;
        background-color: inherit;
    }

    * {
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0) !important; //! Touch highlight color 
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
        box-shadow: inset 1000px 1000px var(--primaryColor1);
    }

    input:focus::placeholder {
        color: transparent;
    }

    input {
        outline: none;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: var(--primaryColor1) !important;
        box-shadow: inset 0 0 0 1000px var(--backgroundColor) !important;
    }

    //! Change the transparent to any color 
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid var(--primaryColor1);
        -webkit-text-fill-color: var(--primaryColor1);
        color: var(--primaryColor1);
        background-color: var(--backgroundColor);
        box-shadow: 0 0 0 1000px var(--backgroundColor) inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    @keyframes autofill {
        to {
            background: var(--backgroundColor);
        }
    }

    a {
        text-decoration: none;
        color: unset;
        -webkit-tap-highlight-color: transparent;
    }

    //! Global Error wrapper
    .global-error-wrap {
        border: 2.5px solid var(--errorRed);
        border-radius: var(--sp1-5x);
        background-color: rgba(222, 85, 78, 0.05);
    }

    //! 404 styles
    .error-404 {
        display:flex;
        align-items: center;
        justify-content: center;
        margin-top: unset !important;
    }

    .un-close {
        font-weight: bold;
    }

    //! Row
    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 calc(var(--colPadding) * -1);

        > * {
            width: 100%;
            padding: 0 var(--colPadding);
        }

        .col {
            flex: 1 1;
        }

        ${columnGenerator()}

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
            ${columnGenerator('dl')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
            ${columnGenerator('dm')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
            ${columnGenerator('ds')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
            ${columnGenerator('dxs')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
            ${columnGenerator('t')}
        }
        
        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
            ${columnGenerator('ts')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
            ${columnGenerator('m')}
        }
    }

    @keyframes loading {
        0%, 100% {
            opacity: 0.7;
        }
        50% {
            opacity: 0.5;
        }
    }
`

export default Typography

function columnGenerator(suffix) {
    let style = ''

    for (let i = 1; i <= 12; i++) {
        style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`
        style += `{ width: ${(100 / 12) * i}% } `
    }

    return style
}
