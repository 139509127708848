import { useRef, useImperativeHandle, forwardRef } from 'react'

const Icon = forwardRef(({ name, className, ...props }, ref) => {
    //!Ref
    const iconRef = useRef()

    //! Imperative Handle
    useImperativeHandle(ref, () => [iconRef.current], [])

    return (
        <i onClick={props.onClick} ref={iconRef} className={`ena-${name} ${className || ''}`}>
            {props.children}
        </i>
    )
})

Icon.displayName = 'Icon'

export default Icon
