import { memo as Memo, useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { useRouter } from 'next/router'

//* HOCs
import withUIContext from 'context/consumerHOC/UIConsumer'
import withDataContext from 'context/consumerHOC/DataConsumer'
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Components
import Logo from 'components/common/Logo'
import Text from 'components/common/Text'
import LottieAnimation from 'components/common/LottieAnimation'
import DesktopMenu from './DesktopMenu'
import BurgerMenu from './BurgerMenu'
import LanguageSwitcher from './LanguageSwitcher'

//* Lottie file
import burgerMenuLottie from 'lottieFiles/burger_menu.json'

//* Style
import HeaderStyle from './style'

//* Menu data
import menuData from './menuData'

//* Helpers
import { config } from 'helpers'

const AppHeader = Memo(({ toggleBurgerMenu, closeBurgerMenu, isBurgerMenuOpen, winWidth, secondHeaderTitles, isSecondHeaderActive, toggleSecondHeader, global, selectedLang }) => {
    //! Global data
    const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    //! Router
    const router = useRouter()

    //! State
    const [scrolledY, setScrolledY] = useState(0)

    //! Ref
    const headerRef = useRef()
    const secondHeaderRef = useRef()
    const titleInnerWrapRef = useRef()

    const menuItems = useMemo(() => {
        return {
            ...menuData,
            1: {
                ...menuData[1],
                children: {
                    ...menuData[1].children,
                    bottom: {
                        ...menuData[1].children.bottom,
                        items: globalData?.sectors,
                    },
                },
            },
        }
    }, [globalData])

    //! Checker
    const isBurgerMenu = useMemo(() => {
        return winWidth <= 1439
    }, [winWidth])

    //! Close burger menu
    useEffect(() => {
        closeBurgerMenu()
        toggleSecondHeader(false)
    }, [router.asPath])

    //! Titles
    const storeTitles = useMemo(() => {
        return secondHeaderTitles.map((menuItem, index) => {
            const headerHeight = headerRef.current?.getBoundingClientRect().height
            const topCount = menuItem.ref.current?.getBoundingClientRect().top + scrolledY - headerHeight
            const bottomCount = secondHeaderTitles[index + 1]?.ref.current?.getBoundingClientRect().top + scrolledY - headerHeight

            const isLast = secondHeaderTitles.length - 1 === index

            const isActive = isLast ? scrolledY >= topCount : scrolledY >= topCount && scrolledY <= bottomCount

            return (
                <div
                    className={`section-title-wrap  ${isActive ? 'active' : ''}`}
                    key={index}
                >
                    <Text
                        className={`p p-2 section-title`}
                        text={menuItem.name}
                        onClick={() => window.scrollTo({ top: topCount + 10, behavior: 'smooth' })}
                    />
                </div>
            )
        })
    }, [secondHeaderTitles, headerRef, scrolledY])

    const onScroll = useCallback(() => {
        setScrolledY(window.scrollY)
    }, [])

    useEffect(() => {
        const items = Array.from(titleInnerWrapRef.current.children)
        const activeLink = items.find((item) => item.classList.contains('active'))

        activeLink && activeLink.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }, [scrolledY])

    const onWheel = useCallback(
        (e) => {
            e.preventDefault()
            let [horizontal, vertical] = [e.deltaX, e.deltaY]
            let magnitude

            if (horizontal === 0) {
                magnitude = vertical > 0 ? -10 : 10
            } else {
                magnitude = horizontal
            }

            secondHeaderRef.current.scrollBy({ left: magnitude })
        },
        [secondHeaderRef]
    )

    useEffect(() => {
        document.addEventListener('scroll', onScroll)
        secondHeaderRef.current?.addEventListener('wheel', onWheel)

        return () => {
            document.removeEventListener('scroll', onScroll)
            secondHeaderRef.current?.removeEventListener('wheel', onWheel)
        }
    }, [onScroll])

    const isHeaderTransparent = useMemo(() => {
        const transparentHeaderItem = Object.values(config.routes).find((item) => item.transparentHeder)

        console.log(transparentHeaderItem)
    }, [])

    return (
        <>
            <HeaderStyle
                ref={headerRef}
                className={`header ${isSecondHeaderActive ? 'second-header-active' : ''} ${isHeaderTransparent}`}
            >
                <div className={`header-content-wrap ${isSecondHeaderActive ? 'hide' : ''}`}>
                    <Logo className={`logo-wrapper`} />

                    <div className={`right-flex-wrapper`}>
                        {!isBurgerMenu && <DesktopMenu menuData={menuItems} />}

                        <div className={`language-icon-wrapper`}>
                            <LanguageSwitcher />
                        </div>

                        <div
                            onClick={toggleBurgerMenu}
                            className={`burger-menu-icon-wrap cursor-pointer`}
                        >
                            <LottieAnimation
                                animData={burgerMenuLottie}
                                loop={false}
                                autoplay={false}
                                isPlay={isBurgerMenuOpen}
                                isReverse={!isBurgerMenuOpen}
                            />
                        </div>
                    </div>
                </div>
                <div
                    ref={secondHeaderRef}
                    className={`titles-wrap ${isSecondHeaderActive ? '' : 'hide'}`}
                >
                    <div
                        ref={titleInnerWrapRef}
                        className='titles-inner-wrap'
                    >
                        {storeTitles}
                    </div>
                </div>
            </HeaderStyle>

            {isBurgerMenu && <BurgerMenu menuData={menuItems} />}
        </>
    )
})

export default withLanguageContext(
    withUIContext(withDataContext(AppHeader, ['global']), ['closeBurgerMenu', 'isBurgerMenuOpen', 'toggleBurgerMenu', 'winWidth', 'secondHeaderTitles', 'isSecondHeaderActive', 'toggleSecondHeader']),
    ['selectedLang']
)
