import { memo as Memo, useCallback } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Component
import Image from 'components/common/Image'

//* Style
import LogoStyle from './style'

const Logo = Memo(({ closeBurgerMenu, className, isWhite }) => {
    //! Router
    const router = useRouter()

    const handleLogoClick = useCallback(() => {
        closeBurgerMenu()

        if (router.pathname !== '/') {
            router.push('/')
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [router.pathname])

    return (
        <LogoStyle
            onClick={handleLogoClick}
            className={`cursor-pointer ${className || ''}`}
        >
            <Image
                src={isWhite ? `/logo-white.svg` : `/logo.svg`}
                alt={`Logo`}
            />
        </LogoStyle>
    )
})

export default withUIContext(Logo, ['closeBurgerMenu'])
