import styled from 'styled-components'

const MenuItemCollapseStyle = styled.li`
	--collapsePadding: var(--sp4x);
	--toggleIconSize: var(--sp3x);
	--collapseBodyPadTop: var(--sp6x);
	--menuInnerItemWidth: 50%;
	--itemDistance: var(--sp2x);
	--lineDistance: var(--sp3x);
	--bottomTitleMBot: var(--sp2x);

	background-color: var(--white);
	padding: var(--collapsePadding);
	border-radius: var(--sp2x);

	&:not(:last-child) {
		margin-bottom: var(--sp1-5x);
	}

	.collapse-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		.toggle-icon {
			position: relative;
			width: var(--toggleIconSize);
			height: var(--toggleIconSize);

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				height: 2px;
				background-color: var(--textBlack100);
			}

			&:before {
				transform: translateY(-50%);
			}

			&:after {
				transform: rotate(90deg) translateX(-1px);
				transition: transform var(--trTime);
			}
		}
	}

	.collapse-body-cont {
		overflow: hidden;

		.collapse-body {
			padding-top: var(--collapseBodyPadTop);

			.menu-inner-cont {
				display: flex;
				flex-wrap: wrap;
				margin: 0 calc(-1 * var(--colPadding));
				margin-bottom: calc(-1 * var(--itemDistance));

				.menu-inner-item {
					width: var(--menuInnerItemWidth);
					padding: 0 var(--colPadding);
					margin-bottom: var(--itemDistance);

					.link-item {
						.comp-title {
							transition: color var(--trTime);
						}

						@media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
							&:hover {
								.comp-title {
									color: var(--red100);
								}
							}
						}
					}

					.link-item {
						&.active-menu {
							pointer-events: none;

							.comp-title {
								color: var(--red100);
							}
						}
					}
				}
			}

			.body-bottom-wrapper {
				border-top: 1px solid var(--textBlack20);
				margin-top: var(--lineDistance);
				padding-top: var(--lineDistance);

				.body-bottom-title {
					margin-bottom: var(--bottomTitleMBot);
					cursor: auto;
				}
			}
		}
	}

	&.active {
		.toggle-icon {
			&:after {
				transform: rotate(0) translateY(-50%);
			}
		}
	}

	.not-parent.active-page {
		color: var(--red100);
		pointer-events: none;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
		--collapseBodyPadTop: var(--sp5x);

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			--collapsePadding: var(--sp3x);

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
				--collapseBodyPadTop: var(--sp4x);
				--toggleIconSize: var(--sp2-5x);
				--menuInnerItemWidth: 100%;
				--itemDistance: var(--sp2-5x);
				--lineDistance: var(--sp2-5x);
			}
		}
	}
`

export default MenuItemCollapseStyle
