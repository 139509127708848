import { memo as Memo, useRef, useEffect, useState, useCallback } from 'react'
import { withRouter } from 'next/router'
import Link from 'next/link'

//* HOCs
import withUIContext from 'context/consumerHOC/UIConsumer'
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Component
import Icon from 'components/common/Icon'

//* Style
import LanguageSwitcherStyle from './style'

const LanguageSwitcher = Memo(({ languages, className, isBurgerMenuOpen, ...props }) => {
	//! Refs
	const contRef = useRef()
	const oldScrollVal = useRef(0)

	//! State
	const [opened, setOpened] = useState(false)

	//! Window Click Listener for close
	const handleWindowClick = useCallback(
		(e) => {
			if (!contRef.current[0].contains(e.target)) {
				setOpened(false)
			}
		},
		[opened]
	)

	//! Close the drop-down of the language in case of the scroll to the down
	const onScroll = useCallback((e) => {
		if (window.scrollY > oldScrollVal.current && window.scrollY > 0) {
			setOpened(false)
		}

		oldScrollVal.current = window.scrollY
	}, [])

	//! Did Mount
	useEffect(() => {
		window.addEventListener('click', handleWindowClick)

		return () => {
			window.removeEventListener('click', handleWindowClick)
		}
	}, [])

	//! Scroll
	useEffect(() => {
		window.addEventListener('scroll', onScroll)

		return () => {
			window.removeEventListener('scroll', onScroll)
		}
	}, [onScroll])

	return (
		<LanguageSwitcherStyle className={`${className || ''} ${opened ? 'opened' : ''}`}>
			<Icon
				ref={contRef}
				name={'globus'}
				className={`h6 cursor-pointer`}
				onClick={() => setOpened(!opened)}
			/>

			<div className={`languages-cont`}>
				{Object.keys(languages).map((lang) => {
					return (
						<Link
							key={lang}
							locale={lang}
							href={props.router.asPath}
							className={`lang-item ${languages[lang].params.code === props.router.locale ? 'active' : ''}`}
						>
							{languages[lang].params.name}
						</Link>
					)
				})}
			</div>
		</LanguageSwitcherStyle>
	)
})

export default withRouter(withLanguageContext(withUIContext(LanguageSwitcher, ['isBurgerMenuOpen']), ['languages']))
