import { createGlobalStyle } from 'styled-components'

const HelperClass = createGlobalStyle`
    //! RED COLORS
    .red-100 {
        color: var(--red100);

        &-bg {
            background-color: var(--red100);
        }
    }

    .red-80 {
        color: var(--red80);

        &-bg {
            background-color: var(--red80);
        }
    }

    .red-60 {
        color: var(--red60);

        &-bg {
            background-color: var(--red60);
        }
    }

    .red-40 {
        color: var(--red40);

        &-bg {
            background-color: var(--red40);
        }
    }

    .red-20 {
        color: var(--red20);

        &-bg {
            background-color: var(--red20);
        }
    }

    .red-10 {
        color: var(--red10);

        &-bg {
            background-color: var(--red10);
        }
    }

    .red-5 {
        color: var(--red5);

        &-bg {
            background-color: var(--red5);
        }
    }

    //! GREEN COLORS
    .green-120 {
        color: var(--green120);

        &-bg {
            background-color: var(--green120);
        }
    }

    .green-100 {
        color: var(--green100);

        &-bg {
            background-color: var(--green100);
            color: var(--white);
        }
    }

    .green-80 {
        color: var(--green80);

        &-bg {
            background-color: var(--green80);
        }
    }

    .green-60 {
        color: var(--green60);

        &-bg {
            background-color: var(--green60);
        }
    }

    .green-40 {
        color: var(--green40);

        &-bg {
            background-color: var(--green40);
        }
    }

    .green-20 {
        color: var(--green20);

        &-bg {
            background-color: var(--green20);
        }
    }

    .green-10 {
        color: var(--green10);

        &-bg {
            background-color: var(--green10);
        }
    }

    .green-5 {
        color: var(--green5);

        &-bg {
            background-color: var(--green5);
        }
    }

    //! textBlack COLORS
    .textBlack-100 {
        color: var(--textBlack100);

        &-bg {
            background-color: var(--textBlack100);
        }
    }

    .textBlack-80 {
        color: var(--textBlack80);

        &-bg {
            background-color: var(--textBlack80);
        }
    }

    .textBlack-60 {
        color: var(--textBlack60);

        &-bg {
            background-color: var(--textBlack60);
        }
    }

    .textBlack-40 {
        color: var(--textBlack40);

        &-bg {
            background-color: var(--textBlack40);
        }
    }

    .textBlack-20 {
        color: var(--textBlack20);

        &-bg {
            background-color: var(--textBlack20);
        }
    }

    .textBlack-10 {
        color: var(--textBlack10);

        &-bg {
            background-color: var(--textBlack10);
        }
    }

    .textBlack-5 {
        color: var(--textBlack5);

        &-bg {
            background-color: var(--textBlack5);
        }
    }

    .bgGray {
        color: var(--bgGray);

        &-bg {
            background-color: var(--bgGray);
        }
    }

    .white {
        color: var(--white);

        &-bg {
            background-color: var(--white);
        }
    }

    .successGreen {
        color: var(--successGreen);

        &-bg {
            background-color: var(--successGreen);
        }
    }

    .errorRed {
        color: var(--errorRed);

        &-bg {
            background-color: var(--errorRed);
        }
    }

    .chromeGreen {
        color: var(--chromeGreen);

        &-bg {
            background-color: var(--chromeGreen);
        }
    }

    //! Font families
    .font-montserrat {
        font-family: var(--montserrat);
        font-weight: 400 !important;

        &-medium {
            font-family: var(--montserrat);
            font-weight: 500 !important;
        }

        &-semibold {
            font-family: var(--montserrat);
            font-weight: 600 !important;
        }
    }

    //! Font sizes
    h1, .h1 {
        font-size: var(--h1);
        line-height: var(--lineHeightH1);
        margin: 0;

        &-L {
            font-size: var(--h1L);
        }

        &-XL {
            font-size: var(--h1XL);
        }
    }

    h2, .h2 {
        font-size: var(--h2);
        line-height: var(--lineHeightH2);
        margin: 0;
    }

    h3, .h3 {
        font-size: var(--h3);
        line-height: var(--lineHeightH3);
        margin: 0;
    }

    h4, .h4 {
        font-size: var(--h4);
        line-height: var(--lineHeightH4);
        margin: 0;
    }

    h5, .h5 {
        font-size: var(--h5);
        line-height: var(--lineHeightH5);
        margin: 0;
    }

    h6, .h6 {
        font-size: var(--h6);
        line-height: var(--lineHeightH6);
        margin: 0;
    }

    p, .p {
        font-size: var(--p1);
        line-height: var(--lineHeightP1);
        margin: 0;

        &-2 {
            font-size: var(--p2);
            line-height: var(--lineHeightP2);
        }
        &-3 {
            font-size: var(--p3);
            line-height: var(--lineHeightP3);
        }
        &-4 {
            font-size: var(--p4);
            line-height: var(--lineHeightP4);
        }
    }
    
    //! Border radius
    .borderRadius {
        &L {
            border-radius: var(--radiusL);
        }

        &M {
            border-radius: var(--radiusM);
        }
        
        &S {
            border-radius: var(--radiusS);
        }
    }

    .capitalize {
        text-transform: capitalize;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .underline {
        text-decoration: underline;
    }
`

export default HelperClass
