import { memo as Memo, useMemo, useState, useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

//* Components
import CustomLink from 'components/common/CustomLink'
import Icon from 'components/common/Icon'
import Text from 'components/common/Text'

//* Style
import MenuItemStyle from './style'

const MenuItem = Memo(({ name, path, page, text, children, className }) => {
    //! Router
    const router = useRouter()

    //! Ref
    const itemRef = useRef()

    //! State
    const [activeMenu, setActiveMenu] = useState()

    //! Window Click Listener for close
    const handleWindowClick = useCallback(
        (e) => {
            if (!itemRef.current.contains(e.target)) {
                setActiveMenu(false)
            }
        },
        [activeMenu]
    )

    //! Did Mount
    useEffect(() => {
        window.addEventListener('click', handleWindowClick)

        return () => {
            window.removeEventListener('click', handleWindowClick)
        }
    }, [])

    const makeMenuItems = (items) => {
        return (
            <ul className='menu-inner-cont'>
                {items?.length > 0 &&
                    items.map((item, key) => (
                        <li
                            key={key}
                            className={`menu-inner-item`}
                        >
                            <CustomLink
                                url={item.path}
                                className={`${(router.asPath.split('/')[2] === item.path.split('/')[2] && router.asPath.split('/')[2] !== 'sector') ||
                                    (router.asPath.split('/')[3] === item.path.split('/')[3] && router.asPath.split('/')[2] === 'sector')
                                    ? 'active-menu'
                                    : ''
                                    }`}
                            >
                                <Text
                                    tag={`span`}
                                    className={`p-2 font-montserrat textBlack-100`}
                                    text={item.name}
                                />
                            </CustomLink>
                        </li>
                    ))}
            </ul>
        )
    }

    const childrenPopup = useMemo(() => {
        return (
            children && (
                <div className={`menu-children-cont`}>
                    <div className={`menu-children-inner-cont`}>
                        <div className={`left-inner-wrapper`}>
                            {/* <Text
								tag={`h5`}
								className={`h5 font-montserrat textBlack-100`}
								text={text}
							/> */}
                        </div>

                        <div className={`right-inner-wrapper`}>
                            <div className={`right-inner-top-wrapper`}>{makeMenuItems(children.top.items)}</div>

                            {children.bottom && (
                                <div className={`right-inner-bottom-wrapper`}>
                                    <Text
                                        tag={`p`}
                                        className={`p-2 font-montserrat textBlack-40 bottom-title`}
                                        text={children.bottom.title}
                                    />

                                    {makeMenuItems(children.bottom.items)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )
        )
    }, [children, router, text])

    return (
        <MenuItemStyle
            ref={itemRef}
            onClick={() => setActiveMenu(!activeMenu)}
            className={`menu-item ${router.pathname === path || router.asPath.includes(page) ? 'active' : ''} ${activeMenu ? 'opened' : ''} ${className || ''}`}
        >
            {path ? (
                <CustomLink
                    url={path}
                    className={`note-parent-wrap`}
                >
                    <Text
                        tag={`p`}
                        className={`p-2 font-montserrat textBlack-100`}
                        text={name}
                    />
                </CustomLink>
            ) : (
                <>
                    <div className={`flex-center-wrapper cursor-pointer`}>
                        <Text
                            tag={`p`}
                            className={`p-2 font-montserrat textBlack-100`}
                            text={name}
                        />

                        <Icon
                            name={`dropdown-arrow`}
                            className={`p-4`}
                        />
                    </div>

                    {childrenPopup}
                </>
            )}
        </MenuItemStyle>
    )
})

export default MenuItem
