import { getParamsByKey } from './_getParamsByKey'

export const routerToPathAndQuery = (router, key, val, multiple, clearKeyItems) => {
	let newVal = val?.toString()

	if (multiple) {
		let paramsArr = getParamsByKey(router, key)
		if (paramsArr) {
			paramsArr = paramsArr.indexOf(newVal) > -1 ? paramsArr.filter((k) => k !== newVal) : [...paramsArr, newVal]
			newVal = paramsArr.join(',')
		}
	}

	const queryObj = { ...router.query, [key]: newVal }
	delete queryObj['params']

	if (clearKeyItems) {
		delete queryObj[clearKeyItems]
	}

	if (router.query[key] === newVal || !newVal) {
		delete queryObj[key]
	}

	const paramsPath = router.query.params && router.query.params.join('/')
	let pathname = router.pathname
	pathname = pathname.replace('[[...params]]', paramsPath || '')

	return { pathname, query: queryObj }
}
