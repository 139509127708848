import { Component, createContext } from 'react'
import { withRouter } from 'next/router'

//* Context
import withLanguageContext from '../consumerHOC/LanguageConsumer'

//* Helper's
import { getData, api } from 'helpers'

const DataContext = createContext(null)
export const DataConsumer = DataContext.Consumer

class DataProvider extends Component {
	state = { ...this.props.newData, prevRoute: null }

	methods = {
		enableLoading: this.enableLoading.bind(this),
		disableLoading: this.disableLoading.bind(this),
		subscribe: this.subscribe.bind(this),
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const lang = this.props.router.locale || this.props.selectedLang

		if (prevProps.router.asPath !== this.props.router.asPath || prevProps.router.locale !== this.props.router.locale) {
			this.setState({ loading: true })
			getData({ lang, state: this.state, router: this.props.router }).then((res) => {
				this.setState({ ...res, loading: false, prevRoute: prevProps.router })
			})
		}
	}

	enableLoading() {
		this.setState({ loading: true })
	}

	disableLoading() {
		this.setState({ loading: false })
	}

	subscribe(data) {
		return api.post('subscribe', data)
	}

	render() {
		return <DataContext.Provider value={{ ...this.state, ...this.methods }}>{this.props.children}</DataContext.Provider>
	}
}

export default withRouter(withLanguageContext(DataProvider, ['selectedLang']))
