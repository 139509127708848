import { memo as Memo, useMemo } from 'react'

//* HOCs
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'
import withDataContext from 'context/consumerHOC/DataConsumer'
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Helper
import config from 'helpers/_config'

//* Components
import CustomLink from 'components/common/CustomLink'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'

//* Style
import FooterStyle from './style'

const AppFooter = Memo(({ global, selectedLang, translate, winWidth }) => {
    //! Global data
    const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    const storeWhyArmeniaMenuItems = useMemo(() => {
        return Object.values(config.routes).map(
            (item, index) =>
                item.isWhyArmeniaFooterMenu && (
                    <CustomLink
                        key={index}
                        className={'menu-link font-montserrat white p p-3'}
                        url={item.path}
                    >
                        {translate(item.name)}
                    </CustomLink>
                )
        )
    }, [selectedLang])

    const storeBusinessEnvironmentItems = useMemo(() => {
        return Object.values(config.routes).map(
            (item, index) =>
                item.isBusinessEnvironmentFooterMenu && (
                    <CustomLink
                        key={index}
                        className={'menu-link font-montserrat white p p-3'}
                        url={item.path}
                    >
                        {translate(item.name)}
                    </CustomLink>
                )
        )
    }, [selectedLang])

    const storeBusinessOpportunitiesItems = useMemo(() => {
        return globalData?.sectors?.map((item, index) => (
            <CustomLink
                key={index}
                className={'menu-link font-montserrat white p p-3'}
                url={item.path}
            >
                {translate(item.name)}
            </CustomLink>
        ))
    }, [globalData?.sectors])

    const storeAboutUsItems = useMemo(() => {
        return Object.values(config.routes).map(
            (item, index) =>
                item.isAboutUsFooterMenu && (
                    <CustomLink
                        key={index}
                        className={'menu-link font-montserrat white p p-3'}
                        url={item.path}
                    >
                        {translate(item.name)}
                    </CustomLink>
                )
        )
    }, [selectedLang])

    const storeMediaCenterItems = useMemo(() => {
        return Object.values(config.routes).map(
            (item, index) =>
                item.isMediaCenterFooterMenu && (
                    <CustomLink
                        key={index}
                        className={'menu-link font-montserrat white p p-3'}
                        url={item.path}
                    >
                        {translate(item.name)}
                    </CustomLink>
                )
        )
    }, [selectedLang])

    return (
        globalData && (
            <FooterStyle className={`footer green-100-bg`}>
                <Container row>
                    <div className='col-3 col-t-4 col-ts-6'>
                        <Text
                            className={'h6 white font-montserrat-medium'}
                            text={'whyArmenia'}
                        />
                        <div className='menu-list'>{storeWhyArmeniaMenuItems}</div>
                        <Text
                            className={'font-montserrat-medium p p-3 green-60 mini-title'}
                            text={'businessEnvironment'}
                        />
                        <div className='menu-list'>{storeBusinessEnvironmentItems}</div>
                    </div>
                    <div className='col-3 col-t-4 col-ts-6'>
                        <Text
                            className={'h6 white font-montserrat-medium'}
                            text={'businessOpportunities'}
                        />
                        <CustomLink
                            className={'first-link p p-3 white font-montserrat'}
                            url={config.routes.incentives.path}
                        >
                            {translate(config.routes.incentives.name)}
                        </CustomLink>
                        <Text
                            className={'font-montserrat-medium p p-3 green-60 mini-title'}
                            text={'sectors'}
                        />
                        <div className='menu-list business-opportunities'>{storeBusinessOpportunitiesItems}</div>
                        <CustomLink
                            className={'menu-link publications font-montserrat white p p-3'}
                            url={config.routes.publications.path}
                        >
                            {translate(config.routes.publications.name)}
                        </CustomLink>
                    </div>
                    <div className='col-3 col-t-4 col-ts-12'>
                        <Container
                            full
                            row
                            className='about-and-media-wrap'
                        >
                            <div className='col-12 col-ts-6'>
                                <Text
                                    className={'h6 white font-montserrat-medium'}
                                    text={'aboutUs'}
                                />
                                <div className='menu-list'>{storeAboutUsItems}</div>
                            </div>
                            <div className='col-12 col-ts-6'>
                                <Text
                                    className={'h6 white media-centre-title font-montserrat-medium'}
                                    text={'media'}
                                />
                                <div className='menu-list'>{storeMediaCenterItems}</div>
                            </div>
                        </Container>
                    </div>
                    <div className='col-3 col-t-12'>
                        <Container
                            className={'infos-cont'}
                            row
                            full
                        >
                            <div className='info-col col-12 col-t-4 col-ts-6 col-m-12'>
                                <Image
                                    className={'enterprise-armenia-logo'}
                                    src={'/images/footer/enterprise-armenia.png'}
                                />
                            </div>
                            <div className='info-col col-12 col-t-4 col-ts-6 col-m-12'>
                                <div className='address-and-phone-wrap'>
                                    <div className='address-block'>
                                        <Text className={'p p-2 white font-montserrat'}>
                                            <span className={' font-montserrat-semibold'}>{translate('addressWithSymbol')}</span> {globalData.address.address}
                                        </Text>
                                    </div>
                                    <div className='phone-block'>
                                        <Text className={'white font-montserrat'}>
                                            <span className={'p p-2 font-montserrat-semibold'}>{translate('phoneWithSymbol')}</span>{' '}
                                            <a
                                                rel='noreferrer noopener'
                                                href={`tel:${globalData.phone.number}`}
                                                aria-label='phone'
                                                className='p p-2'
                                            >
                                                {globalData.phone.number}
                                            </a>
                                        </Text>
                                    </div>
                                </div>
                            </div>

                            {winWidth >= 768 && winWidth < 1024 && <div className='col-ts-6'></div>}
                            <div className='info-col col-12 col-t-4 col-ts-6 col-m-12'>
                                {globalData.social.socials && Object.values(globalData.social.socials).filter((item) => item !== null).length !== 0 && (
                                    <div className='socials-wrap'>
                                        {globalData.social.socials.linkedin && (
                                            <CustomLink
                                                url={globalData.social.socials.linkedin}
                                                target={'_blank'}
                                                className={'social-link'}
                                            >
                                                <Icon
                                                    className={'social-icon white'}
                                                    name={'linkedin'}
                                                />
                                            </CustomLink>
                                        )}
                                        {globalData.social.socials.facebook && (
                                            <CustomLink
                                                url={globalData.social.socials.facebook}
                                                target={'_blank'}
                                                className={'social-link'}
                                            >
                                                <Icon
                                                    className={'social-icon white'}
                                                    name={'facebook'}
                                                />
                                            </CustomLink>
                                        )}
                                        {globalData.social.socials.twitter && (
                                            <CustomLink
                                                url={globalData.social.socials.twitter}
                                                target={'_blank'}
                                                className={'social-link'}
                                            >
                                                <Icon
                                                    className={'social-icon white'}
                                                    name={'tumbler'}
                                                />
                                            </CustomLink>
                                        )}
                                        {globalData.social.socials.youtube && (
                                            <CustomLink
                                                url={globalData.social.socials.youtube}
                                                target={'_blank'}
                                                className={'social-link'}
                                            >
                                                <Icon
                                                    className={'social-icon white'}
                                                    name={'youtube'}
                                                />
                                            </CustomLink>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className='col-12'>
                                <Text
                                    className={'p p-3 support-text'}
                                    text={'supportText'}
                                />
                            </div>
                            <div className='info-col col-12 col-ds-12 col-ts-6 col-m-12'>
                                <div className='eu-logos-wrap'>
                                    <a
                                        href='https://www.ebrd.com/home'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                        aria-label='European bank'
                                    >
                                        <Image
                                            className={'eu-bank'}
                                            src={'/images/footer/eu-bank.png'}
                                        />
                                    </a>
                                    <a
                                        href='https://european-union.europa.eu/index_en'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                        aria-label='European union'
                                    >
                                        <Image
                                            className={'eu-union'}
                                            src={'/images/footer/eu-union.png'}
                                        />
                                    </a>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className='copyright-block'>
                        <Text className={'p p-3 font-montserrat-medium white'}>
                            © {new Date().getFullYear()} {translate('rightsText')}
                        </Text>
                        <Text className={'p p-3 font-montserrat white concept-link-wrap'}>
                            {translate('madeBy')}{' '}
                            <a
                                rel='noreferrer noopener'
                                aria-label='concept link'
                                href='https://conceptstudio.com/en/'
                                target='_blank'
                                className='underline'
                            >
                                Concept Studio
                            </a>
                        </Text>
                    </div>
                </Container>
            </FooterStyle>
        )
    )
})

export default withUIContext(withLanguageContext(withDataContext(AppFooter, ['global']), ['selectedLang', 'translate']), ['winWidth'])
