export default {
    colors: {
        red100: '#962F34',
        red80: '#AB595D',
        red60: '#C08285',
        red40: '#D5ACAE',
        red20: '#EAD5D6',
        red10: '#F5EBEB',
        red5: '#F9F4F5',
        green120: '#134B47',
        green100: '#1C5551',
        green80: '#497774',
        green60: '#779997',
        green40: '#A4BBB9',
        green20: '#D2DDDC',
        green10: '#E9EEEE',
        green5: '#FAFBFB',
        textBlack100: '#262626',
        textBlack80: '#515151',
        textBlack60: '#7D7D7D',
        textBlack40: '#A8A8A8',
        textBlack20: '#D4D4D4',
        textBlack10: '#EAEAEA',
        textBlack5: '#F4F4F4',
        bgGray: '#F5F5F5',
        white: '#ffffff',
        successGreen: '#79C43E',
        errorRed: '#FF5C52',
        chromeGreen: '#E3F0D2',
    },

    mediaQuery: {
        DesktopSizeXL: '2560px',
        DesktopSizeL: '1920px',
        DesktopSizeM: '1510px',
        DesktopSizeS: '1440px',
        DesktopSizeXS: '1280px',
        TabletSize: '1024px',
        TabletSizeS: '768px',
        MobileSize: '601px',
        MobileSizeS: '398px',

        DesktopSizeXLMin: '2559px',
        DesktopSizeLMin: '1919px',
        DesktopSizeMMin: '1509px',
        DesktopSizeSMin: '1439px',
        DesktopSizeXSMin: '1279px',
        TabletSizeMin: '1023px',
        TabletSizeSMin: '767px',
        MobileSizeMin: '600px',
    },
}
