import { memo as Memo, useMemo } from 'react'

//* HOC
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Component
import MenuItemCollapse from './MenuItemCollapse'

//* Style
import BurgerMenuStyle from './style'

const BurgerMenu = Memo(({ isBurgerMenuOpen, menuData }) => {
	//! Menu Items
	const menuItems = useMemo(() => {
		return (
			<ul>
				<MenuItemCollapse {...menuData[0]} />

				<MenuItemCollapse {...menuData[1]} />

				<MenuItemCollapse {...menuData[3]} />

				<MenuItemCollapse {...menuData[4]} />

				<MenuItemCollapse {...menuData[2]} />

				<MenuItemCollapse {...menuData[5]} />
			</ul>
		)
	}, [menuData])

	return (
		<BurgerMenuStyle className={`${isBurgerMenuOpen ? `active` : ''}`}>
			<div className={`burger-menu-cont`}>
				<div className={`burger-menu-inner-cont`}>{menuItems}</div>
			</div>
		</BurgerMenuStyle>
	)
})

export default withUIContext(BurgerMenu, ['isBurgerMenuOpen'])
