import styled from 'styled-components'

const DesktopMenuStyle = styled.nav`
	--menuItemDistance: var(--sp8x);

	.menu-cont {
		display: flex;
		align-items: center;
		margin: 0 calc(-1 * calc(var(--menuItemDistance) / 2));

		.menu-item {
			margin: 0 calc(var(--menuItemDistance) / 2);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--menuItemDistance: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--menuItemDistance: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--menuItemDistance: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--menuItemDistance: 0;

		display: none;
	}
`

export default DesktopMenuStyle
