import { memo as Memo } from 'react'
import { withRouter } from 'next/router'
import _ from 'lodash'
import template from 'lodash.template'

//* Context
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

const Translate = Memo((props) => {
	let compiled = props.languages[props.router.locale]?.[props.val] && template(props.languages[props.router.locale]?.[props.val])
	return (compiled && compiled(props.vars)) || props.val || null
})

export default withRouter(withLanguageContext(Translate, '*'))
