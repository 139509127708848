import styled from 'styled-components'

const LanguageSwitcherStyle = styled.div`
	--borderRadiusSize: var(--sp1x);
	--contentPadTB: var(--sp3x);
	--contentPadLR: var(--sp4x);
	--langItemMTop: var(--sp2x);

	.languages-cont {
		position: absolute;
		top: 100%;
		right: var(--headerContentPadLR);
		opacity: 0;
		transition: opacity var(--trTime);
		background-color: var(--white);
		box-shadow: 0px 8px 12px rgba(38, 38, 38, 0.15);
		border-radius: var(--borderRadiusSize);
		display: flex;
		flex-direction: column;
		padding: var(--contentPadTB) var(--contentPadLR);
		z-index: 5;
		pointer-events: none;
		cursor: default;

		.lang-item {
			&:not(:first-child) {
				margin-top: var(--langItemMTop);
			}

			@media (hover: hover) {
				&:hover {
					color: var(--red100);
					text-decoration: underline;
				}
			}

			&.active {
				color: var(--red100);
				text-decoration: underline;
				pointer-events: none;
			}
		}
	}

	&.opened {
		.languages-cont {
			opacity: 1;
			pointer-events: all;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		.languages-cont {
			right: calc(var(--headerContentPadLR) + var(--sp8x));
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
			.languages-cont {
				right: calc(var(--headerContentPadLR) + var(--sp7x));
			}
		}
	}
`

export default LanguageSwitcherStyle
