import React from 'react'
import App from 'next/app'
import dynamic from 'next/dynamic'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Draggable from 'gsap/dist/Draggable'
import InertiaPlugin from 'gsap/dist/InertiaPlugin'

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin)
}

//* Providers
import { UIProvider, DataProvider, LanguageProvider } from 'context'

//* Helper's
import { getData, getPageParams, config, getUser } from 'helpers'

//* Component's
const HighLevelComponent = dynamic(import('../components/common/HighLevelComponent/index.js'), { ssr: false })
import AppHeader from 'components/common/AppHeader'
import AppFooter from 'components/common/AppFooter'
import Popup from 'components/common/Popup'

//* Styles
import 'styles/antd.normalize.css'
import 'styles/icons/style.css'
import 'swiper/css'

class MyApp extends App {
    static async getInitialProps({ Component, ctx, router, ...props }) {
        const isMobileView = (ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent).match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)

        //! Checking If First Request or Not
        if (typeof window === 'undefined') {
            //! Default States
            const newState = {
                pages: {},
                global: {},
            }

            //! Getting Page Data
            const data = await getData({ lang: ctx.locale, state: newState, router: ctx, getPageParams: true })

            return {
                pageProps: {
                    ...data,
                    browser: false,
                    isMobileView,
                },
            }
        } else {
            return {
                pageProps: {
                    pageParams: getPageParams({ lang: ctx.locale, router: ctx }),
                    browser: true,
                    isMobileView,
                },
            }
        }
    }

    render() {
        const { Component, pageProps, router } = this.props

        return (
            <>
                <LanguageProvider>
                    <UIProvider>
                        <DataProvider newData={pageProps}>
                            {router?.state?.pathname !== '/404' && <AppHeader />}

                            <Component
                                {...pageProps}
                                config={config.routes}
                                selectedLang={router.locale}
                            />

                            <Popup />

                            <HighLevelComponent isMobileView={pageProps.isMobileView} />

                            {router?.state?.pathname !== '/404' && <AppFooter />}
                        </DataProvider>
                    </UIProvider>
                </LanguageProvider>
            </>
        )
    }
}

export default MyApp
